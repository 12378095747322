@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600&display=swap");

html,body{
  margin: 0;
  padding: 0;
}
body {
  font-family: "Noto Sans JP", sans-serif !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

aside.EmojiPickerReact.epr-main {
  border-radius: none!important;
}

* {
  box-sizing: border-box;
}